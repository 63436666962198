// App promo landing page styles.
.shards-app-promo-page--1 {

      // Welcome section.
      // Sets up the background cover image and the overlay gradient.
      .welcome {
        position: relative;
        height: 100vh;
        min-height: 700px;
        background: url('../images/app-promo/welcome-cover.jpg') no-repeat center center fixed;
        background-size: cover;

        &:before {
          position: absolute;
          z-index: 0;
          content: '';
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: .9;
          background: theme-color("dark");
        }

        // Responsive adjustments.
        @include media-breakpoint-down(md) {
          height: auto;

          .header-social-icons { display: none; }
        }

        @include media-breakpoint-down(sm) {
          height: auto;

          .inner-wrapper {
            padding-top: $spacer * 3;
            text-align: center;
          }
        }

        @media (max-width: 400px) {
          .welcome-heading { font-size: 2.415rem; }
        }

        // Fine tuning the width as there's no utility class for 85 (yet).
        .iphone-mockup { max-width: 85%; }
      }

      // Custom section title styling.
      // Adds the small border at the bottom of each section title.
      .section-title {
        position: relative;

        &:after {
          content: '';
          width: 30px;
          height: 2px;
          background: theme-color("success");
          position: absolute;
          left: 50%;
          margin-left: -15px;
          bottom: -20px;
        }

        // Modifier that positions the bottom border in the left side.
        &.underline--left {
          &:after {
            left: 0;
            margin-left: 0;
          }
        }
      }

      // App screenshot.
      .app-screenshot {
        background: url('../images/app-promo/features-background.jpg') no-repeat center center fixed;
        background-size: cover;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: theme-color("light");
          opacity: .6;
        }

        img {
          max-width: 300px;
          position: relative;
          z-index: 1;
          top: 50%;
          left: 100%;
          transform: translate(-50%, -50%);
          box-shadow: 0 13px 25px rgba(#000000, .05),
                      0 60px 100px rgba(#C0C0C0, .5);

          @include media-breakpoint-down(md) {
            left: 50%;
            transform: translateX(-50%, 0);
          }
        }
      }

      // Product feature (individual elements).
      .feature {
        .icon {
          border-radius: 50%;
          min-width: 65px;
          height: 65px;
          line-height: 65px;
          text-align: center;
          box-shadow: 0 2px 4px rgba(#212529, .1), 0 7px 14px rgba(#212529, .1);
          font-size: 27px;

          @include media-breakpoint-down(sm) {
            margin-right: $spacer !important;
          }
        }
      }

      // Better separation between sections.
      .section { border-bottom: 1px solid lighten($blueish-grey, 54); }

      // Section background variation for better separation.
      .section-invert { background: #f9fafc; }

      // Testimonials section avatar styling.
      .testimonials .avatar {
        max-width: 80px;
        overflow: hidden;
      }

      // Subscribe box.
      .subscribe {
        @include media-breakpoint-down(sm) {
          input, button { width: 100%; }
        }
      }
    }
